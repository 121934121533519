const getters = {
  isSuperAdmin: state => state.companyID == 0,
  isAuthenticated: state => !!state.username,
  companyID: state => state.companyID,
  username: state => state.username,
  userFullname: state => state.userFullname,
  userTitle: state => state.userTitle,
  roleID: state => state.roleID,
  token: state => state.token
}

export default getters
