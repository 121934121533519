import axios from 'axios'
import router from '../../router'
import { jwtDecode } from 'jwt-decode'

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

const actions = {
  async login({ commit }, authData) {
    try {
      const response = await axios.post(`${apiBaseUrl}/csms/login`, authData)
      const token = response.data.token
      const decodedToken = jwtDecode(token)
      localStorage.setItem('token', token)
      localStorage.setItem('userID', decodedToken.userID)
      localStorage.setItem('roleID', decodedToken.roleID)
      localStorage.setItem('username', decodedToken.username)
      localStorage.setItem('userFullname', decodedToken.userFirstname + ' ' + decodedToken.userLastname)
      localStorage.setItem('userTitle', decodedToken.userTitle)
      localStorage.setItem('companyID', decodedToken.companyID)
      commit('setToken', token)
      commit('setUserID', decodedToken.userID)
      commit('setRoleID', decodedToken.roleID)
      commit('setUsername', decodedToken.username)
      commit('setUserFullname', decodedToken.userFirstname + ' ' + decodedToken.userLastname)
      commit('setUserTitle', decodedToken.userTitle)
      commit('setCompanyID', decodedToken.companyID)
      router.push('/')
    } catch (error) {
      console.error('Login failed:', error)
      throw error
    }
  },
  logout({ commit }) {
    localStorage.clear()
    commit('clearAuthData')
    router.push('/auth/login')
  }
}

export default actions
