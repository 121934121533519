const mutations = {
  setUserID(state, userID) {
    state.userID = userID
  },
  setUsername(state, username) {
    state.username = username
  },
  setUserFullname(state, userFullname) {
    state.userFullname = userFullname
  },
  setUserTitle(state, userTitle) {
    state.userTitle = userTitle
  },
  setCompanyID(state, companyID) {
    state.companyID = companyID
  },
  setRoleID(state, roleID) {
    state.roleID = roleID
  },
  setToken(state, token) {
    state.token = token
  },
  clearAuthData(state) {
    state.userID = null
    state.username = null
    state.companyID = null
    state.roleID = null
    state.token = ''
  }
}

export default mutations