<template>
  <!--Logo start-->
  <div class="logo-main">
    <img :src="logoSrc" alt="Logo" width="30" height="30" />
  </div>
  <!--Logo End-->
</template>

<script>
export default {
  props: {
    color: { type: Boolean, default: false }
  },
  computed: {
    logoSrc() {
      return require('@/assets/images/logo/logo-d.png'); // Replace with the actual path to your image
    }
  }
}
</script>