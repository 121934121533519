const state = {
  companyID: parseInt(localStorage.getItem('companyID'), 10) || null,
  username: localStorage.getItem('username') || null,
  userFullname: localStorage.getItem('userFullname') || null,
  userTitle: localStorage.getItem('userTitle') || null,
  roleID: parseInt(localStorage.getItem('roleID'), 10) || null,
  token: localStorage.getItem('token') || null
}

export default state
